import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import "../scss/index.scss";

import {LayoutPages, Seo} from "../components";
import Contacts from '../modules/Contacts/containers/Contacts';

const ContactPage = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulContacts {
                  subtitle_ru
                  subtitle_en
                  subtitle_cn
                  seo {
                    title_ru
                    title_en
                    title_cn
                    description_ru
                    description_en
                    description_cn
                    keywords_ru
                    keywords_en
                    keywords_cn
                  }
                  imgBg {
                    id
                    gatsbyImageData
                    title
                  }
                }
              }
            `}
            render={data => {
                const breadcrumbs = [
                    {
                        pathname: "/", crumbLabel: `${languageSelect === 'ru' ? 'Главная' : languageSelect === 'en' ? 'Home' : '首頁'}`, active: false
                    },
                    {
                        pathname: "", crumbLabel: `${data.contentfulContacts["subtitle_" + languageSelect]}`, active: true
                    }
                ];
                return (
                    <LayoutPages titlePage={data.contentfulContacts["subtitle_" + languageSelect]}
                                 breadcrumbs={breadcrumbs}
                                 breadcrumbsClass={'breadcrumbs_color_white'}
                                 contacts={true}
                                 contactsImgBg={data.contentfulContacts.imgBg}
                    >
                        <Seo title={`${data.contentfulContacts.seo["title_" + languageSelect]}`}
                             description={`${data.contentfulContacts.seo["description_" + languageSelect]}`}
                             keywords={`${data.contentfulContacts.seo["keywords_" + languageSelect]}`}
                        />
                        <Contacts/>
                    </LayoutPages>
                );
            }}
        />

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(ContactPage);
