import React, {useMemo, useState} from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";

import FormFeedbackContacts from '../../FormFeedback/containers/FormFeedbackContacts';
import cn from "classnames";


const ContactsContainer = ({languageSelect}) => {

  const [cityContacts, setCityContacts] = useState(null);

  return (
    <StaticQuery
      query={graphql`
              query  {
                contentfulContacts {
                    subtitle_ru
                    subtitle_en
                    subtitle_cn
                    email
                    whatsApp
                    telegram
                    phone
                    instagram
                    vkontakte
                    youtube
                    facebook
                    cities {
                      id
                      title_ru
                      title_en
                      title_cn
                      phone
                      whatsapp
                      telegram
                      mail
                      youtube
                      vk
                      instagram
                    }
                  }
              }
            `}
      render={data => {

        setCityContacts(cityContacts != null ? cityContacts : data.contentfulContacts.cities[0]);

        if(cityContacts == null) {
          return null;
        }

        return (
          <section className="section section-contacts">
            <div className="container">
              <div className="frame">
                <div className="frame-item frame-top">
                  <div className="frame-item__el frame-top__frame-item-el_right"></div>
                  <div className="frame-item__el frame-top__frame-item-el_left"></div>
                </div>
                <div className="frame-item frame-bottom">
                  <div className="frame-item__el frame-bottom__frame-item-el_left"></div>
                  <div className="frame-item__el frame-bottom__frame-item-el_right"></div>
                </div>
              </div>
              <div className="contacts">
                <div className="section-head">
                  <h1 className="subtitle subtitle_lvl_one subtitle_color_white section-page__subtitle">
                    {data.contentfulContacts["subtitle_" + languageSelect]}
                  </h1>
                </div>
                <div className="contacts-tabs">
                  {data.contentfulContacts.cities.map((item_city) => (
                    <div
                      className={cn("contacts-tabs__item", {"contacts-tabs__item--active": cityContacts.id === item_city.id})} onClick={() => {
                      setCityContacts(item_city);
                    }}>
                      <span className="menu-hover menu-hover_bottom"></span>
                      <span className="menu-hover menu-hover_top"></span>
                      {item_city["title_" + languageSelect]}
                    </div>
                  ))}
                </div>
                <div className="contacts-wrapper">
                  <div className="contacts-left">
                    <div className="contacts-element-wrapper">
                      <div className="contacts-item">
                        <svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1"
                             xmlns="http://www.w3.org/2000/svg">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-1232.000000, -4356.000000)" fill="#E91E27" fillRule="nonzero">
                              <path
                                d="M1257.99623,4367.00082 C1258.00126,4366.94293 1258.00126,4366.88472 1257.99623,4366.82683 C1257.99623,4366.81883 1257.99623,4366.81183 1257.99623,4366.80383 C1257.98337,4366.76214 1257.96766,4366.72138 1257.94921,4366.68184 C1257.93214,4366.62149 1257.90937,4366.56289 1257.88118,4366.50686 C1257.81543,4366.4166 1257.74462,4366.33013 1257.66909,4366.24788 C1257.61968,4366.20963 1257.56675,4366.17615 1257.51102,4366.14788 C1257.47598,4366.12207 1257.43921,4366.09869 1257.40097,4366.07789 L1233.39052,4356.07864 C1233.01131,4355.91877 1232.57284,4356.00733 1232.28549,4356.30182 C1231.99814,4356.59631 1231.92055,4357.03665 1232.08996,4357.41154 L1236.40183,4367.00082 L1232.08796,4376.5901 C1231.94881,4376.8995 1231.97608,4377.2583 1232.16041,4377.54313 C1232.34473,4377.82796 1232.66096,4378 1233.00035,4378 C1233.1326,4378.00029 1233.26356,4377.97411 1233.38552,4377.923 L1257.39597,4367.92375 C1257.43421,4367.90295 1257.47098,4367.87956 1257.50602,4367.85375 C1257.56175,4367.82548 1257.61467,4367.792 1257.66409,4367.75376 C1257.80153,4367.64262 1257.89999,4367.49062 1257.94521,4367.31979 C1257.96365,4367.28025 1257.97936,4367.23949 1257.99223,4367.1978 C1257.99223,4367.1898 1257.99223,4367.18281 1257.99223,4367.17481 C1257.99859,4367.11703 1258,4367.05882 1257.99623,4367.00082 Z M1252.00863,4366.00089 L1238.14859,4366.00089 L1234.9472,4358.90143 L1252.00863,4366.00089 Z M1234.9502,4375.10021 L1238.15159,4368.00074 L1252.00863,4368.00074 L1234.9502,4375.10021 Z"
                                id="Shape"></path>
                            </g>
                          </g>
                        </svg>
                        <a href={'tel:' + cityContacts.phone}
                           className="footer-contact__item">{cityContacts.phone}</a>
                      </div>
                      {
                        languageSelect !== 'cn' &&
                        <>
                          {cityContacts.whatsapp != "" && (
                            <div className="contacts-item">
                              <svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1"
                                   xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g transform="translate(-1232.000000, -4356.000000)" fill="#1ebea5" fillRule="nonzero">
                                    <path
                                      d="M1257.99623,4367.00082 C1258.00126,4366.94293 1258.00126,4366.88472 1257.99623,4366.82683 C1257.99623,4366.81883 1257.99623,4366.81183 1257.99623,4366.80383 C1257.98337,4366.76214 1257.96766,4366.72138 1257.94921,4366.68184 C1257.93214,4366.62149 1257.90937,4366.56289 1257.88118,4366.50686 C1257.81543,4366.4166 1257.74462,4366.33013 1257.66909,4366.24788 C1257.61968,4366.20963 1257.56675,4366.17615 1257.51102,4366.14788 C1257.47598,4366.12207 1257.43921,4366.09869 1257.40097,4366.07789 L1233.39052,4356.07864 C1233.01131,4355.91877 1232.57284,4356.00733 1232.28549,4356.30182 C1231.99814,4356.59631 1231.92055,4357.03665 1232.08996,4357.41154 L1236.40183,4367.00082 L1232.08796,4376.5901 C1231.94881,4376.8995 1231.97608,4377.2583 1232.16041,4377.54313 C1232.34473,4377.82796 1232.66096,4378 1233.00035,4378 C1233.1326,4378.00029 1233.26356,4377.97411 1233.38552,4377.923 L1257.39597,4367.92375 C1257.43421,4367.90295 1257.47098,4367.87956 1257.50602,4367.85375 C1257.56175,4367.82548 1257.61467,4367.792 1257.66409,4367.75376 C1257.80153,4367.64262 1257.89999,4367.49062 1257.94521,4367.31979 C1257.96365,4367.28025 1257.97936,4367.23949 1257.99223,4367.1978 C1257.99223,4367.1898 1257.99223,4367.18281 1257.99223,4367.17481 C1257.99859,4367.11703 1258,4367.05882 1257.99623,4367.00082 Z M1252.00863,4366.00089 L1238.14859,4366.00089 L1234.9472,4358.90143 L1252.00863,4366.00089 Z M1234.9502,4375.10021 L1238.15159,4368.00074 L1252.00863,4368.00074 L1234.9502,4375.10021 Z"
                                      id="Shape"></path>
                                  </g>
                                </g>
                              </svg>
                              <a href={cityContacts.whatsapp} target="_blank"
                                 className="footer-contact__item">WhatsApp</a>
                            </div>
                          )}
                          {cityContacts.telegram != "" && (
                            <div className="contacts-item">
                              <svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1"
                                   xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g transform="translate(-1232.000000, -4356.000000)" fill="#32afed" fillRule="nonzero">
                                    <path
                                      d="M1257.99623,4367.00082 C1258.00126,4366.94293 1258.00126,4366.88472 1257.99623,4366.82683 C1257.99623,4366.81883 1257.99623,4366.81183 1257.99623,4366.80383 C1257.98337,4366.76214 1257.96766,4366.72138 1257.94921,4366.68184 C1257.93214,4366.62149 1257.90937,4366.56289 1257.88118,4366.50686 C1257.81543,4366.4166 1257.74462,4366.33013 1257.66909,4366.24788 C1257.61968,4366.20963 1257.56675,4366.17615 1257.51102,4366.14788 C1257.47598,4366.12207 1257.43921,4366.09869 1257.40097,4366.07789 L1233.39052,4356.07864 C1233.01131,4355.91877 1232.57284,4356.00733 1232.28549,4356.30182 C1231.99814,4356.59631 1231.92055,4357.03665 1232.08996,4357.41154 L1236.40183,4367.00082 L1232.08796,4376.5901 C1231.94881,4376.8995 1231.97608,4377.2583 1232.16041,4377.54313 C1232.34473,4377.82796 1232.66096,4378 1233.00035,4378 C1233.1326,4378.00029 1233.26356,4377.97411 1233.38552,4377.923 L1257.39597,4367.92375 C1257.43421,4367.90295 1257.47098,4367.87956 1257.50602,4367.85375 C1257.56175,4367.82548 1257.61467,4367.792 1257.66409,4367.75376 C1257.80153,4367.64262 1257.89999,4367.49062 1257.94521,4367.31979 C1257.96365,4367.28025 1257.97936,4367.23949 1257.99223,4367.1978 C1257.99223,4367.1898 1257.99223,4367.18281 1257.99223,4367.17481 C1257.99859,4367.11703 1258,4367.05882 1257.99623,4367.00082 Z M1252.00863,4366.00089 L1238.14859,4366.00089 L1234.9472,4358.90143 L1252.00863,4366.00089 Z M1234.9502,4375.10021 L1238.15159,4368.00074 L1252.00863,4368.00074 L1234.9502,4375.10021 Z"
                                      id="Shape"></path>
                                  </g>
                                </g>
                              </svg>
                              <a href={cityContacts.telegram} target="_blank"
                                 className="footer-contact__item">Telegram</a>
                            </div>
                          )}
                        </>

                      }
                      <div className="contacts-item">
                        <svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1"
                             xmlns="http://www.w3.org/2000/svg">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-1232.000000, -4356.000000)" fill="#E91E27" fillRule="nonzero">
                              <path
                                d="M1257.99623,4367.00082 C1258.00126,4366.94293 1258.00126,4366.88472 1257.99623,4366.82683 C1257.99623,4366.81883 1257.99623,4366.81183 1257.99623,4366.80383 C1257.98337,4366.76214 1257.96766,4366.72138 1257.94921,4366.68184 C1257.93214,4366.62149 1257.90937,4366.56289 1257.88118,4366.50686 C1257.81543,4366.4166 1257.74462,4366.33013 1257.66909,4366.24788 C1257.61968,4366.20963 1257.56675,4366.17615 1257.51102,4366.14788 C1257.47598,4366.12207 1257.43921,4366.09869 1257.40097,4366.07789 L1233.39052,4356.07864 C1233.01131,4355.91877 1232.57284,4356.00733 1232.28549,4356.30182 C1231.99814,4356.59631 1231.92055,4357.03665 1232.08996,4357.41154 L1236.40183,4367.00082 L1232.08796,4376.5901 C1231.94881,4376.8995 1231.97608,4377.2583 1232.16041,4377.54313 C1232.34473,4377.82796 1232.66096,4378 1233.00035,4378 C1233.1326,4378.00029 1233.26356,4377.97411 1233.38552,4377.923 L1257.39597,4367.92375 C1257.43421,4367.90295 1257.47098,4367.87956 1257.50602,4367.85375 C1257.56175,4367.82548 1257.61467,4367.792 1257.66409,4367.75376 C1257.80153,4367.64262 1257.89999,4367.49062 1257.94521,4367.31979 C1257.96365,4367.28025 1257.97936,4367.23949 1257.99223,4367.1978 C1257.99223,4367.1898 1257.99223,4367.18281 1257.99223,4367.17481 C1257.99859,4367.11703 1258,4367.05882 1257.99623,4367.00082 Z M1252.00863,4366.00089 L1238.14859,4366.00089 L1234.9472,4358.90143 L1252.00863,4366.00089 Z M1234.9502,4375.10021 L1238.15159,4368.00074 L1252.00863,4368.00074 L1234.9502,4375.10021 Z"
                                id="Shape"></path>
                            </g>
                          </g>
                        </svg>
                        <a href={'mailto:' + cityContacts.mail}
                           className="footer-contact__item">{cityContacts.mail}</a>
                      </div>
                      <div className="contacts-item contacts-item_social">
                        {
                          languageSelect !== 'cn' && cityContacts.youtube != "" &&
                          <a href={cityContacts.youtube} className="footer-social__link" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 29 21"
                                 fill="none">
                              <path
                                d="M24.0923 0H4.90769C3.60609 0 2.3578 0.540831 1.43743 1.50352C0.517059 2.4662 0 3.77189 0 5.13333V15.8667C0 17.2281 0.517059 18.5338 1.43743 19.4965C2.3578 20.4592 3.60609 21 4.90769 21H24.0923C25.3939 21 26.6422 20.4592 27.5626 19.4965C28.4829 18.5338 29 17.2281 29 15.8667V5.13333C29 3.77189 28.4829 2.4662 27.5626 1.50352C26.6422 0.540831 25.3939 0 24.0923 0ZM15.7269 12.8333L11.3769 15.1667V5.83333L15.7269 8.16667L20.0769 10.5L15.7269 12.8333Z"
                                fill="white"/>
                            </svg>
                          </a>
                        }
                        {cityContacts.vk != "" && (
                          <a href={cityContacts.vk} className="footer-social__link" target="_blank">
                            <svg width="16" height="16" viewBox="0 0 21 21" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M13.7221 0H7.27595C1.39351 0 -0.000976562 1.39449 -0.000976562 7.27693V13.7231C-0.000976562 19.6055 1.39351 21 7.27595 21H13.7221C19.6045 21 20.999 19.6055 20.999 13.7231V7.27693C20.999 1.39449 19.5908 0 13.7221 0ZM16.9521 14.9804H15.4276C14.8502 14.9804 14.6723 14.5224 13.6335 13.4698C12.7312 12.595 12.3315 12.4789 12.1091 12.4789C11.7947 12.4789 11.7055 12.5675 11.7055 12.9986V14.376C11.7055 14.7455 11.5893 14.9673 10.6116 14.9673C8.99462 14.9673 7.20049 13.9895 5.94249 12.1678C4.04533 9.49827 3.52626 7.49873 3.52626 7.08531C3.52626 6.86285 3.61485 6.65482 4.04205 6.65482H5.56976C5.95628 6.65482 6.10327 6.83266 6.25355 7.24609C7.00887 9.42674 8.26686 11.337 8.78594 11.337C8.97756 11.337 9.06615 11.2484 9.06615 10.7595V8.50736C9.00774 7.46855 8.4611 7.3793 8.4611 7.0105C8.4611 6.83266 8.6081 6.65482 8.84369 6.65482H11.2461C11.571 6.65482 11.6904 6.83266 11.6904 7.21524V10.2536C11.6904 10.5817 11.8374 10.6979 11.926 10.6979C12.1176 10.6979 12.2817 10.5817 12.6367 10.226C13.7339 8.99559 14.5201 7.09843 14.5201 7.09843C14.6224 6.87597 14.8003 6.66795 15.1868 6.66795H16.7145C17.1725 6.66795 17.2749 6.90353 17.1725 7.23165C16.9809 8.12018 15.1113 10.7589 15.1113 10.7589C14.9506 11.0253 14.8889 11.1415 15.1113 11.4387C15.2754 11.6612 15.8089 12.1225 16.1639 12.536C16.8169 13.2775 17.3189 13.8996 17.4528 14.3301C17.603 14.7573 17.3812 14.9798 16.9501 14.9798L16.9521 14.9804Z"
                                fill="white"/>
                            </svg>
                          </a>
                        )}
                        {/*<a href={data.contentfulContacts.facebook} className="footer-social__link" target="_blank">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 21" fill="none">*/}
                        {/*        <path d="M15.749 0.0043694L13.0258 0C9.96639 0 7.98925 2.02849 7.98925 5.16813V7.55098H5.2512C5.01459 7.55098 4.823 7.7428 4.823 7.9794V11.4319C4.823 11.6685 5.01481 11.8601 5.2512 11.8601H7.98925V20.5718C7.98925 20.8084 8.18085 21 8.41745 21H11.9898C12.2264 21 12.418 20.8082 12.418 20.5718V11.8601H15.6195C15.8561 11.8601 16.0477 11.6685 16.0477 11.4319L16.049 7.9794C16.049 7.8658 16.0037 7.757 15.9236 7.6766C15.8434 7.5962 15.7342 7.55098 15.6206 7.55098H12.418V5.53101C12.418 4.56013 12.6494 4.06726 13.9141 4.06726L15.7486 4.0666C15.985 4.0666 16.1766 3.87479 16.1766 3.6384V0.432571C16.1766 0.196405 15.9852 0.00480634 15.749 0.0043694Z" fill="white"/>*/}
                        {/*    </svg>*/}
                        {/*</a>*/}
                      </div>
                    </div>
                  </div>
                  <div className="contacts-right">
                    <FormFeedbackContacts/>
                  </div>
                </div>

              </div>
            </div>
          </section>
        );
      }}
    />
  );
};


let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect["languageSelect"],
  }
};

export default connect(mapStateToProps)(ContactsContainer);
